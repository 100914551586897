import styled, { css } from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Wrapper = styled.section`
  background: ${grayscale[100]};
`
export const Section = styled.div`
  position: relative;
  /* padding-top: 40px;
  padding-bottom: 40px; */

  @media ${device.tablet} {
    /* padding-top: 64px;
    padding-bottom: 64px; */
  }
`

export const Title = styled.h3`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${grayscale[500]};
  margin-bottom: 0;
  padding-right: 4px;
`

export const ContentWrapper = styled.div`
  width: 152px;
  cursor: pointer;

  p {
    padding-top: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: ${grayscale[500]};
    width: 148px;

    margin-bottom: 0;
    &:hover {
      color: ${orange.base};
    }

    @media ${device.tablet} {
      font-size: 16px;
      line-height: 19px;
      background: ${white};
      padding: 24px;
      border-radius: 0px 0px 16px 16px;
      width: 336px;
      border: 1px solid ${grayscale[200]};
    }

    @media ${device.desktopLG} {
      width: 296px;
    }
    @media ${device.desktopXL} {
      width: 360px;
    }
    @media ${device.desktopXXXL} {
      width: 460px;
    }
 }
`

export const VideoWrapper = styled.div<{ video: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background: rgba(22, 22, 22, 0.48) url(${(props: { video: string }) => props.video ? props.video : ''}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 148px;
  height: 148px;
  border-radius: 4px;

  &::before {
    position: absolute;
    content: '';
    background: rgba(22, 22, 22, 0.48);
    width: 148px;
    height: 148px;
    border-radius: 4px;
  }

  @media ${device.tablet} {
    width: 336px;
    height: 200px;
    border-radius: 16px 16px 0px 0px;

    &::before {
      width: 336px;
      height: 200px;
      border-radius: 16px 16px 0px 0px;
    }
  }

  @media ${device.desktopLG} {
    width: 296px;
    height: 200px;

    &::before {
      width: 296px;
      height: 200px;
    }
  }

  @media ${device.desktopXL} {
    width: 360px;
    height: 200px;

    &::before {
      width: 360px;
      height: 200px;
    }
  }
  @media ${device.desktopXXXL} {
    width: 460px;
    height: 200px;

    &::before {
      width: 460px;
      height: 200px;
    }
  }

  img {
    z-index: 1;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: ${grayscale[500]};

    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: ${white};
    padding: 2px 4px;
    border-radius: 4px;

  }

`

export const Divisor = styled.div`
  border: 1px dashed #DEDFE4;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 24px;
  margin-right: 24px;
`

export const Chevron = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;
  margin-top: 24px;

  ${(props: { active: boolean }) => props.active && css`
    transform: rotate(-180deg);
  `}

  cursor: pointer;
`
