import React, { useState, useEffect } from 'react'
import ChevronMore from '@inter/icons/orangeds/MD/chevron-down'


import useWidth from 'src/hooks/window/useWidth'

import { Acordion } from './acordion/_index'

import { Chevron } from './acordion/style'
import { Section, Title } from './style'

import type { ArticlesModel, ArticlesPropsModel } from '../../types'
import CustomSelect from 'src/components/CustomSelect'

const WIDTH_MD = 768
const WIDTH_LG = 992

type TopicProps = {
  data: ArticlesModel;
  setSelectedTopic: (data: ArticlesPropsModel) => void;
  sendDatalayerEvent: Function;
  setSelectedCategory: (data: string) => void;
}

enum SelectOptions {
  ATOZ = 'atoz',
  ZTOA = 'ztoa',
  DEFAULT = 'default'
}

export const Topics = ({ data, setSelectedTopic, sendDatalayerEvent, setSelectedCategory }: TopicProps) => {
  const [ quantityToShow, setQuantityToShow ] = useState(3)
  const [ showAll, setShowAll ] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(SelectOptions.DEFAULT)
  const [articles, setArticles] = useState(data)

  const windowWidth = useWidth(300)

  useEffect(() => {
    if (windowWidth < WIDTH_MD) setQuantityToShow(3)
    if (windowWidth >= WIDTH_MD) setQuantityToShow(4)
    if (windowWidth >= WIDTH_LG) setQuantityToShow(6)
  }, [ windowWidth ])

  const options = [
    { text: 'Ordem padrão', value: 'default'},
    { text: 'Alfabetica: A-Z', value: 'atoz'},
    { text: 'Alfabetica: Z-A', value: 'ztoa'},
  ]

  const handleChangeOrder = (value: SelectOptions) => {
    setSelectedOrder(value)
  }

  useEffect(() => {

    if (selectedOrder === SelectOptions.ATOZ) {
      const sortedEntries = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]));
      const sortedObj = Object.fromEntries(sortedEntries);

      setArticles(sortedObj)

    } else if (selectedOrder === SelectOptions.ZTOA) {
      const sortedEntries = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0])).reverse();
      const sortedObj = Object.fromEntries(sortedEntries);

      setArticles(sortedObj)
    } else if (selectedOrder === SelectOptions.DEFAULT) {
      setArticles(data)
    } else {
      setArticles(data)
    }
  }, [selectedOrder])

  return (
    <Section className='container pt-4 pb-4'>
      <div className='row d-flex justify-content-between align-items-center'>
        <div className='col-12 col-md-8'>
          <Title>Leia tudo sobre o Inter</Title>
        </div>
        <div className="col-12 col-md-4 col-xl-3">
          <CustomSelect onChange={handleChangeOrder} value={selectedOrder} options={options}/>
        </div>
      </div>
      <div className='row'>
        {
          Object.entries(articles).slice(0, showAll ? 999 : quantityToShow).map((article: [string, ArticlesPropsModel[]], index: number) => (
            <div key={index} className='col-12 col-md-6 col-lg-4 mb-4'>
              <Acordion topic={article} setSelectedTopic={setSelectedTopic} sendDatalayerEvent={sendDatalayerEvent} setSelectCategory={setSelectedCategory}/>
            </div>
            ))
        }
      </div>
      <div className='row'>
        <div className='col-12'>
          <Chevron active={showAll} onClick={() => setShowAll(!showAll)}>
            <ChevronMore color='#FF7A00' width={48} height={48}/>
          </Chevron>
        </div>
      </div>
    </Section>
  )
}
