import React from 'react'
import { createPortal } from 'react-dom'
import './modal.scss'

function Modal ({ children, isModalOpen, setIsModalOpen, locationToRender }) {
  function closeModal () {
    setIsModalOpen(false)
  }

  return (
    isModalOpen && (
      createPortal(
        <div className='modal-container'>
          <div className='modal-container__backdrop' onClick={closeModal} />
          {children}
        </div>,
        locationToRender,
      )
    )
  )
}

export default Modal
