import React, { useEffect, useState } from 'react'

// import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import OrangeIcon from 'src/components/Icons'
import { Container, HeaderWrapper } from './style'
import { Title } from '../style'

type CarouselProps = {
  children: React.ReactNode | React.ReactNode[];
  show: number;
  title: string;
}

export const Carousel = ({ children, show, title }: CarouselProps) => {
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [ length, setLength ] = useState(React.Children.count(children))

  const [ transitionEnabled ] = useState(true)

  const [ touchPosition, setTouchPosition ] = useState(0)

  useEffect(() => {
    setLength(React.Children.count(children))
  }, [ children, show ])

  const next = () => {
    if (currentIndex < (length - show)) {
      setCurrentIndex((prevState: number) => prevState + 1)
    }
  }

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState: number) => prevState - 1)
    }
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      next()
    }

    if (diff < -5) {
      prev()
    }

    setTouchPosition(0)
  }

  return (
    <Container className='carousel-container'>
      <div className='carousel-wrapper'>
        <HeaderWrapper>
          <Title>{title}</Title>
          <div>
            <div onClick={prev}>
              <OrangeIcon size='MD' color={currentIndex > 0 ? '#ff7a00' : '#DEDFE4'} icon='chevron-left' />
            </div>
            <div onClick={next}>
              <OrangeIcon size='MD' color={currentIndex < (length - show) ? '#ff7a00' : '#DEDFE4'} icon='chevron-right' />
            </div>
          </div>
        </HeaderWrapper>

        <div
          className='carousel-content-wrapper'
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`carousel-content show-${show}`}
            style={{
              transform: `translateX(-${currentIndex * (100 / show)}%)`,
              transition: !transitionEnabled ? 'none' : undefined,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </Container>
  )
}
