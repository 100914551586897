import React, { useEffect, useState } from 'react'
import NotificationCenter from '@inter/icons/orangeds/MD/notification-center'
import ChevronMore from '@inter/icons/orangeds/MD/chevron-right'
import ChevronLess from '@inter/icons/orangeds/MD/chevron-up'

import { useBreakpoint } from 'src/hooks/window/useBreakpoint'
import { TrackData } from '@interco/track'
import categoriesJSON from 'src/assets/data/faq-babi/categories.json'
import { navigate } from "gatsby"

import {
  Card,
  HeaderWrapper,
  HeaderTitleWrapper,
  HeaderTitle,
  Chevron,
  Logo,
  Topic,
  ButtonShowMore,
  FirstContent
} from './style'

import type { ArticlesPropsModel } from '../../../types'
import { useLocation } from '@reach/router'
import { normalizeString } from 'src/utils/normalizeString'

type AcordionProps = {
  topic: [string, ArticlesPropsModel[]];
  setSelectedTopic: (data: ArticlesPropsModel) => void;
  sendDatalayerEvent: Function;
  setSelectCategory: (data: string) => void;
}

export const Acordion = ({ topic, setSelectedTopic, sendDatalayerEvent, setSelectCategory }: AcordionProps) => {
  const breakpoint = useBreakpoint()
  const location = useLocation()
  const [ hidden, setHidden ] = useState(true)
  // const [topicList, setTopicList] = useState<[string, ArticlesPropsModel[]] | []>([])
  const [ chevronColor, setChevronColor ] = useState('#161616')

  // const parseTopic = topic[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(',', '').replace('?', '').replace('/', '').replace(/\s+/g, '-').toLowerCase()
  const parseTopic = normalizeString(topic[0])

  const checkIfHasOrderItem = (a: ArticlesPropsModel, b: ArticlesPropsModel) => {
    if (a.order === null && b.order === null) {
      return 0;
    }
    if (a.order === null) {
      return 1;
    }
    if (b.order === null) {
      return -1;
    }

    return a.order - b.order;
  }

  return (
    <Card active={!hidden}>
      <FirstContent>

        <HeaderWrapper>
          <HeaderTitleWrapper>
            <HeaderTitle>
              {topic[0]}
            </HeaderTitle>
          </HeaderTitleWrapper>
          <Chevron active={!hidden} onClick={() => setHidden(!hidden)} className='d-md-none'>
            <ChevronMore color='#FF7A00' />
          </Chevron>
        </HeaderWrapper>
        

        {(breakpoint.up('md') || (breakpoint.down('md') && !hidden)) && topic[1]?.sort((a, b) => {
          return checkIfHasOrderItem(a, b)
        }).slice(0, 3).map((content: ArticlesPropsModel) => {
          const parseTitle = normalizeString(content.title)
          const category = categoriesJSON.pt.find(item => item.uuid === content.category_id)?.title
          const constructUrlLink = `/${parseTopic}/${parseTitle}`
          
          return (
            <Topic
              onClick={() => TrackData.log({ conteudo: content.title, url: window.location.href, evento: 'click', categoria: category  })}
              key={content.title}
              href={constructUrlLink}
            >
              {content.title}
            </Topic>
          )
        })}
      </FirstContent>
      {topic[1].length > 3 && (
        <ButtonShowMore
          className='d-none d-md-flex'
          onClick={() => {
            TrackData.log({ conteudo: 'Conferir mais artigos' })
            sendDatalayerEvent({
              section: 'dobra_2',
              element_action: 'click button',
              element_name: topic[0] + ' - Conferir mais artigos',
              section_name: 'Confira tudo sobre o Inter',
            })
            navigate(`/categories/${parseTopic}`)
          }}
          onMouseOver={() => setChevronColor('#FF7A00')}
          onMouseOut={() => setChevronColor('#161616')}
        >
          <p>Conferir mais artigos</p>
          <div className='chevron'>
            <ChevronMore color={chevronColor} />
          </div>
        </ButtonShowMore>
      )}
      
    </Card>
  )
}
