import styled from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  position: relative;

  @media ${device.tablet} {
    padding-top: 64px;
    min-height: 1023px;
  }

  @media ${device.desktopLG} {
    min-height: 508px;
  }

  @media ${device.desktopLG} {
    min-height: 508px;
  }
`

export const Title = styled.h2`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${grayscale[500]};

  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 40px;
  }
`
