/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { useState, useEffect, useRef } from 'react'


import ChevronDown from '../../assets/images/chevron-down-solid.svg'

import { DropDownContainer, DropDownHeader, DropDownList, DropDownListContainer, FallbackIcon, ListItem } from './styles'

interface OptionType {
  value: string
  text: string
}

interface CustomSelectProps {
  value: string,
  options: OptionType[],
  onChange: (text: string) => void;
}

const CustomSelect = ({ value: iconValue, options, onChange }: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value)
    onChange(value)
    setIsOpen(false)
  }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  useEffect(() => {
    const startedOption = options.find((i) => i.value === iconValue)
    setSelectedOption(startedOption)
  }, [iconValue, options])

  return (
    <DropDownContainer ref={wrapperRef}>
      <DropDownHeader onClick={toggling} isOpen={isOpen}>
        <div>
          {selectedOption?.text || "Ordernar por"}
        </div>
        <img className="chevron-down" src={ChevronDown} width={16} height={16} alt=""/>
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((option) => (
              <ListItem onClick={onOptionClicked(option.value)} key={Math.random()}>
                {option.text}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  )
}

export default CustomSelect
