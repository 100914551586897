import styled from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  position: relative;
  /* padding-top: 40px;
  padding-bottom: 40px; */

  @media ${device.tablet} {
    /* padding-top: 64px; */
    min-height: 1023px;
  }

  @media ${device.desktopLG} {
    min-height: 508px;
  }

  @media ${device.desktopLG} {
    min-height: 508px;
  }
`

export const Title = styled.h2`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${grayscale[500]};

  margin-bottom: 32px;
`

export const Card = styled.div`
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};
  background: ${white};
  padding: 24px;

  margin-bottom: 24px;

  @media ${device.tablet} {
    min-height: 394px;
  }

  @media ${device.desktopLG} {
    min-height: 442px;
  }

  @media ${device.desktopXL} {
    min-height: 415px;
  }

  &:last-child {
    margin-bottom: 24px;
  }
`

export const CardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;
  margin-bottom: 16px;
`

export const CardTitle = styled.h3`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${grayscale[500]};

  margin-bottom: 0;
  margin-left: 14px;

  @media ${device.desktopLG} {
    margin-right: -12px;
  }
`

export const CardDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${grayscale[400]};

  margin-bottom: 32px;
  margin-right: -2px;

  @media ${device.desktopLG} {
    margin-right: -13px;
  }
`

export const NumberDesc = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};

  margin-bottom: 4px;
`

export const Number = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.base};

  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Link = styled.a`
  text-decoration: none;
  display: block;
  border-radius: 8px;
  border: 1px solid ${orange.base};
  padding: 15px 16px;
  width: 100%;
  background: ${white};

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.base};

  text-align: center;
`

export const Divider = styled.div`
  border: 1px dashed ${grayscale[200]};
  margin-bottom: 24px;
`
