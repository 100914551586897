import React, { ReactNode, useRef, useState } from 'react'

import YoutubeVideo from '../YoutubeVideo'
import Modal from '../Modal';
import useDomReady from 'src/hooks/useDomReady';

type OpenVideoProps = {
  link: string;
  children: ReactNode;
}

function OpenVideo ({ children, link }: OpenVideoProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const domReady = useDomReady()

  const openVideoModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal} locationToRender={document.body}>
        <div className='d-flex justify-content-center align-items-center h-100 w-100' onClick={handleModal}>
          <YoutubeVideo
            src={link}
            width='900'
            height='540'
          />
        </div>
      </Modal>
  )

  return (
    <>
      <div onClick={handleModal}>
        {children}
      </div>
      {openVideoModal}
    </>
  )
}

OpenVideo.defaultProps = {
  link: '',
  children: <></>,
}

export default OpenVideo
