import styled from 'styled-components'

import { grayscale, orange } from 'src/styles/colors'

export const DropDownContainer = styled('div')`
  width: 100%;
  position: relative;
  height: 60px;
`

export const DropDownHeader = styled('div')<{ isOpen: boolean }>`
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${grayscale[100]};

  div {
    font-size: 14px;
    color: ${grayscale[300]};
    font-weight: 600;
    display: flex;
    align-items: center;

    img {
      margin-right: 14px;
    }
  }

  .chevron-down {
    transform: rotate(-90deg);
    transition: transform ease-in-out 0.2s;
  }

  ${(props) =>
    props.isOpen &&
    `
    .chevron-down {
      transform: rotate(0deg)
    }
  `}
`

export const DropDownListContainer = styled('div')`
  margin-top: 4px;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
`

export const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  font-size: 1.3rem;
  font-weight: 500;
  border-radius: 8px;
  overflow: auto;
  max-height: 200px;
`

export const ListItem = styled('li')`
  list-style: none;
  padding: 10px 20px;

  display: flex;
  align-items: center;

  font-size: 14px;

  img {
    margin-right: 14px;
  }

  &:hover {
    cursor: pointer;
    background: #eee;
    border-radius: 6px;
    /* color: ${orange.light}; */
  }

  transition: all ease-in-out 0.2s;
`

export const FallbackIcon = styled.div`
  color: ${orange.base};
  margin-right: 10px;
`