import React, { useState } from 'react'
import { TrackData } from '@interco/track'

import Layout from 'src/components/Layout'
import articles from 'src/assets/data/faq-babi/articles.json'
import videos from 'src/assets/data/faq-babi/videos.json'
// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Hero } from './sections/hero/_index'
import { CentralRelacionamento } from './sections/centralRelacionamento/_index'
import { Topics } from './sections/topics/_index'
import pageContext from './pageContext.json'

import type { ArticlesModel, ArticlesPropsModel } from './types'
import { Playlist } from './sections/playlist/_index'
import { View } from 'src/types/View'


const IndexPage = () => {
  const articlesList: ArticlesModel = articles
  const [ view, setView ] = useState(View.Landing)
  const [ submitedSearch, setSubmitedSearch ] = useState('')
  const softView = view === View.Search || view === View.Topic || view === View.ArticleByCategory
  const hasHeaderTitle = view === View.Search || view === View.Topic || view === View.Landing
  
  const [ sendDatalayerEvent ] = useDataLayer()

  const selectTopic = () => setView(View.Topic)

  TrackData.initOrUpdate({
    runtimeEnv: process.env.NODE_ENV,
    projectName: 'ajuda_inter_co',
  })

  const handleShowMoreArticle = () => setView(View.ArticleByCategory)

  return (
    <Layout pageContext={pageContext}>
      <Hero
        isHomePage
        softView={softView}
        submitedSearch={submitedSearch}
        setSubmitedSearch={setSubmitedSearch}
        view={view}
        setView={setView}
        sendDatalayerEvent={sendDatalayerEvent}
        hasHeaderTitle={hasHeaderTitle}
      />
      <Topics data={articlesList} setSelectedTopic={selectTopic} sendDatalayerEvent={sendDatalayerEvent} setSelectedCategory={handleShowMoreArticle}/>
      {videos.length > 0 && <Playlist />}
      <CentralRelacionamento sendDatalayerEvent={sendDatalayerEvent} />
    </Layout>
  )
}

export default IndexPage
