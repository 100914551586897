import styled, { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white, orange } from 'src/styles/colors'

export const Card = styled.div<{ active: boolean }>`
  border-radius: 16px;
  background: ${grayscale[100]};
  padding: 16px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props: { active: boolean }) => props.active && css`
    min-height: 66px;
  `}

  @media ${device.tablet} {
    padding: 32px;
    margin-bottom: 24px;
    background: ${white};
    border: 1px solid ${grayscale[200]};

    &:hover {
      background: ${grayscale[100]};
    }
  }
`

export const FirstContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 34px;
`

export const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};

  margin-bottom: 0;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 25px;
  }
`

export const Chevron = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  ${(props: { active: boolean }) => props.active && css`
    transform: rotate(-180deg);
  `}

  cursor: pointer;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Topic = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${grayscale[500]};

  margin-bottom: 0;
  margin-top: 24px;

  cursor: pointer;

  &:hover {
    color: ${orange.base};
  }
`

export const SubTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};

  margin-top: 48px;
  margin-bottom: 0;

  @media ${device.tablet} {
    margin-top: 40px;
    font-size: 20px;
    line-height: 25px;
  }
`

export const ButtonShowMore = styled.div`
  display: flex;
  align-items: center;
  background: ${white};
  border-radius: 20px;
  border: 1px solid ${grayscale[500]};
  padding: 3.5px 12px;
  margin-top: 32px;
  width: fit-content;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: ${grayscale[500]};

    margin-bottom: 0;
  }

  cursor: pointer;

  .chevron {
    margin-left: 4px;
  }

  &:hover {
    border: 1px solid ${orange.base};

    p {
      color: ${orange.base}
    }
  }
`
